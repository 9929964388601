import styled from "styled-components"
import { Grid } from "react-styled-flexboxgrid"

const StyledGrid = styled(Grid)`
  @media screen and (max-width: 39.9375em) {
    padding: 1rem;
  }
`

export default StyledGrid
