module.exports = {
  pompadour: "#5d0039",
  canonPink: "#A64553",
  thunder: "#231F20",
  diesel: "#130100",
  goGreen: "#7ca260",
  gallery: "#fafafa",
  white: "#ffffff",
  mineshaft: "#3B3B3B",
  mexired: "#A01C36",
  merlot: "#7D1322",
  lightning: "#FABB15",
  error: "#ff3434",
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    mediaQuery: "only screen",
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
}
