import React from "react"
import styled from "styled-components"
import { calculateRem } from "../../../utils/javascript-styles"

const StyledBreak = styled.hr`
  margin-top: ${calculateRem(100)};
  margin-bottom: ${calculateRem(100)};
  background-color: ${props => props.theme.haiti};
  @media screen and (max-width: 39.9375em) {
    margin-top: ${calculateRem(25)};
    margin-bottom: ${calculateRem(25)};
  }
`

const Break = () => {
  return <StyledBreak />
}

export default Break
