import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { calculateRem } from "../../../utils/javascript-styles"

const StyledSpace = styled.div`
  display: ${props =>
    props.display === "inline" ? "inline-block" : props.display};
  margin-top: ${props => calculateRem(props.mt)};
  margin-right: ${props => calculateRem(props.mr)};
  margin-bottom: ${props => calculateRem(props.mb)};
  margin-left: ${props => calculateRem(props.ml)};
  padding-top: ${props => calculateRem(props.pt)};
  padding-right: ${props => calculateRem(props.pr)};
  padding-bottom: ${props => calculateRem(props.pb)};
  padding-left: ${props => calculateRem(props.pl)};
`

const Space = ({ children, mt, mb, mr, ml, pt, pr, pb, pl, display }) => {
  return (
    <StyledSpace
      display={display}
      mt={mt}
      mb={mb}
      mr={mr}
      ml={ml}
      pt={pt}
      pr={pr}
      pb={pb}
      pl={pl}
    >
      {children}
    </StyledSpace>
  )
}

Space.defaultProps = {
  display: "block",
  mt: 1,
  mb: 1,
  mr: 1,
  ml: 1,
  pt: 0,
  pr: 0,
  pb: 0,
  pl: 0,
}

Space.propTypes = {
  children: PropTypes.node,
  display: PropTypes.string,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  pt: PropTypes.number,
  pr: PropTypes.number,
  pb: PropTypes.number,
  pl: PropTypes.number,
}

export default Space
